import styles from './index.module.css';
import {useState,useEffect} from 'react'
const fetchResponse = await fetch('https://quiz.1000logos.net/quiz.json').then(response => response.json())
const answers = fetchResponse.answers
const alphabet = "abcdefghijklmnopqrstuvwxyz"
function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
const generateLetters = (answer)=>{
    let answerEdited = answer;
    let alphabetCut = alphabet;
    let alphabetLetters = []
    for (let i = 0; i < answer.length; i++) {
        const char = answer.charAt(i)
        if(char === '-' || char === ' '){
            continue;
        }
        if(!answerEdited.replace(char,'').includes(char)){
        alphabetCut = alphabetCut.replace(char,'')
        }
        answerEdited = answerEdited.replace(char,'')
        alphabetLetters.push(char)
      }
      let wrongLetters = []
      if(alphabetLetters.length !== 14){
          const length = alphabetLetters.length;
    for(let i = 0;i < 14 - length; i++) {
        const randomCharacter = alphabetCut[Math.floor(Math.random() * alphabetCut.length)]
        alphabetLetters.push(randomCharacter)
        wrongLetters.push(randomCharacter)
        alphabetCut = alphabetCut.replace(randomCharacter,'')
    }
      }
      let signs = {indexHyphens:[],indexSpace:[]};
      for (var i = 0; i < answer.length; i++) {
        const letter = answer.charAt(i);
        const index = i;
          if(letter === '-'){
            signs.indexHyphens.push({letter,pos:index})
          }else if(letter === ' '){
            signs.indexSpace.push({letter,pos:index})
          }
      }
    return {letters:shuffle(alphabetLetters),signs,wrong:wrongLetters};
}
export default function Frame(props){
    const [seconds,setSeconds] = useState(props.timer);
    const currentAnswer = answers[props.currentIndex].toLowerCase()
    const generated = generateLetters(currentAnswer)
    const allLettersBooleans = [];
    for (let i = 0; i < 14; i++){ allLettersBooleans[i] = false;}
    const [allLetters,setAllLetters] = useState({letters:generated.letters,disabled:allLettersBooleans});
    const [solved,setSolved] = useState(props.timer === -1)
    const [errored,setErrored] = useState(false)
    const [timegone,setTimegone] = useState(false)
    const [wrongLetters,setWrongLetters] = useState(generated.wrong)
    const selectedLettersIndexes = [];
    let currentAnswerLength;
    let currentAnswerMinus = 0;
    generated.signs.indexHyphens.forEach(()=>{
        currentAnswerMinus++;
    })
    generated.signs.indexSpace.forEach(()=>{
        currentAnswerMinus++;
    })
    currentAnswerLength = currentAnswer.length - currentAnswerMinus
    if(currentAnswer.includes('-') || currentAnswer.includes(' ')){

        currentAnswerLength = currentAnswer.length - 1
    }
    else{
        currentAnswerLength = currentAnswer.length
    }
    for (let i = 0; i < currentAnswerLength; i++){selectedLettersIndexes[i] = -1;}
    const selectedLettersIndexesMain = [];
    for (let i = 0; i < 14; i++){selectedLettersIndexesMain[i] = -1;}
    const [selectedLetters,setSelectedLetters] = useState({letters:Array(currentAnswerLength).join(".").split("."),indexes:selectedLettersIndexes,indexesMain:selectedLettersIndexesMain,indexesFor:selectedLettersIndexes})
    const [timeout,setCurTimeout] = useState(null)
    const mql = window.matchMedia("(max-width:920px)");
    const [refresh,setRefresh] = useState(1)
mql.addEventListener("change", () => {
    setRefresh(refresh + 1)
});
    const selectLetter = (letter,curIndex) => {
        let arr = JSON.parse(JSON.stringify(selectedLetters));
        let curAllLetters = JSON.parse(JSON.stringify(allLetters))
        const index = arr.letters.findIndex(e => e === '');
        arr.letters[index] = letter;
        arr.indexesFor[curIndex] = index
        
        arr.indexes[index] = index
        arr.indexesMain[index] = curIndex
        curAllLetters.disabled[curIndex] = true;
        setAllLetters({letters:allLetters.letters,disabled:curAllLetters.disabled})
        setSelectedLetters(arr)
        if(arr.letters.filter(e => e !== '').length === currentAnswerLength){
        if(arr.letters.join('').toLowerCase() === currentAnswer.replace('-','').replace(' ','')){
            
clearTimeout(timeout)
setCurTimeout(null)
            setSolved(true)
            setSeconds(2)
            props.addCoin();
            props.setTimer(-1)
            
        }
        else{
            setErrored(true)
            setTimeout(()=>{setErrored(false)},2000)
            setSelectedLetters({letters:Array(currentAnswerLength).join(".").split("."),indexes:selectedLettersIndexes,indexesMain:selectedLettersIndexesMain,indexesFor:selectedLettersIndexes})
            setAllLetters({letters:allLetters.letters,disabled:Array(false)})
        }
        }
    }
    const unselectLetter = (curIndex,curIndexFor) => {
        let arr = JSON.parse(JSON.stringify(selectedLetters))
        
        let curAllLetters = JSON.parse(JSON.stringify(allLetters))
        if(typeof curIndexFor === 'number'){
            curAllLetters.disabled[arr.indexesMain[curIndexFor]] = false;
        }
        else{
            
        curAllLetters.disabled[arr.indexesMain[curIndex]] = false;
        }
        setAllLetters({letters:allLetters.letters,disabled:curAllLetters.disabled})
        let index;
        if(typeof curIndexFor === 'number'){
        index = arr.indexes[curIndexFor]
        }
        else{
            index = arr.indexes[curIndex]
        }
        arr.letters[index] = '';
        arr.indexes[index] = -1
        setSelectedLetters(arr)
    }
 useEffect(() => {
    if(seconds > 0){
    setCurTimeout(setTimeout(() => {setSeconds(seconds - 1);props.setTimer(seconds - 1)}, 1000))
    }
    else{
        if(props.timer !== -1 && seconds === 0){
setTimegone(true)
clearTimeout(timeout)
        }
    }
    }, [seconds]);
    let selectedLettersRender;
    if(generated.signs.indexHyphens.length > 0 || generated.signs.indexSpace.length > 0){
        const arr = [...selectedLetters.letters];
        let newarr = [];
        let newsigns = [];
        let nowIndex = 0;
        generated.signs.indexHyphens.forEach(obj => {
            
            newarr.push(arr.slice(nowIndex,obj.pos));
            nowIndex = obj.pos;
            newsigns.push(obj.letter)
        })
        if(generated.signs.indexHyphens.length > 0){
            
        newarr.push(arr.slice(nowIndex));
        }
        generated.signs.indexSpace.forEach(obj => {
            newarr.push(arr.slice(nowIndex,obj.pos));
            nowIndex = obj.pos;
            newsigns.push(obj.letter)
        })
        if(generated.signs.indexSpace.length > 0){
            newarr.push(arr.slice(nowIndex));
        }
        let wordNumber = 0;
    selectedLettersRender = currentAnswer.split(/[-\s]+/).map((elem,index) => {
        return (<div style={{display:'flex',justifyContent:'center',alignItems: 'center'}}>
            {newarr[index].map((elem,index) => {
            const now1 = (wordNumber === 0 ? 0 : wordNumber === 1 ? 1 : 2)
            const now = selectedLetters.indexes[index - now1]
          return(
                <div onClick={()=>{unselectLetter(now)}}>
                <p>{elem}</p>
                </div>
            )
            })}
            <p style={{margin:'0 3px',display:mql.matches ? 'none' : 'block'}}>{newsigns[index] ? '\u00a0\u00a0\u00a0\u00a0' : ''}</p>
        </div>)
        });
    }
    else{
    selectedLettersRender = selectedLetters.letters.map((elem,index) => {
        return(
            <div onClick={elem ? ()=>{unselectLetter(selectedLetters.indexes[index])} : null}>
            <p>{elem}</p>
            </div>
        )
    }); 
    }
    return(
        <div className={styles.main}>
        <div className={styles.logocontainer}>
            <img src={'https://1000logos.net/quiz/' + (solved || timegone ? props.logoplus : props.logo)} alt="logo"/>
        </div>
        <div className={styles.input}>
            {generated.signs.indexHyphens.length === 0 && generated.signs.indexSpace.length === 0 ? <div style={{display:'flex'}}>{selectedLettersRender}</div> : selectedLettersRender}
        </div>
        <div className={styles.timer + (solved || props.timer < 0 ? ' ' + styles.timersolved : '') + (errored || timegone ? ' ' + styles.timererrored : '')}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg>
        <p>{solved || props.timer < 0 ? 'Great!' : errored ? 'Wrong! Try again...' : timegone ? 'Time is up!' : seconds + ' sec. left'}</p>
        </div>
        <div className={styles.letters}>
        {allLetters.letters.map((elem,index) => {
            if(allLetters.disabled[index]){

            return(
                <button onClick={wrongLetters.includes(elem) && props.isBomb ? timegone || props.timer === -1 ? null : null : ()=>{unselectLetter(index,selectedLetters.indexesFor[index])}} data-disabled={true} className={styles.letter}>
                    <p>{elem}</p>
                </button>
            )
            }
            else{
                return(
                <button onClick={wrongLetters.includes(elem) && props.isBomb ? timegone || props.timer === -1 ? null : null : ()=>{selectLetter(elem,index)}} data-disabled={(wrongLetters.includes(elem) && props.isBomb) || timegone || props.timer === -1} className={styles.letter}>
                    <p>{elem}</p>
                </button>
            )
            }
        })}
        </div>
        </div>
    )
}