import styles from './index.module.css'
import logo from '../../../mainlogo.png';
import Coins from './Coins'
export default function Header(props){
    return(
    <div className={styles.main}>
        <div onClick={()=>{props.setPage('welcome')}} className={styles.backbutton}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg></div>
        <img src={logo} alt="logo"/>
        <Coins coinCount={props.coinCount}/>
    </div>
    )
}