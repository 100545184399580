import {useState} from 'react'
import Welcome from './components/Welcome'
import Game from './components/Game'
function App() {
  const [page,setPage] = useState('welcome')
  switch(page){
    case 'welcome':
      return <Welcome setPage={setPage}/>
    case 'game':
      return <Game setPage={setPage}/>
    default:
      return <Welcome setPage={setPage}/>
  }
}

export default App;
