import icontick from '../../11.png';
import iconcross from '../../22.png';
import iconarrow from '../../33.png';
import iconbomb from '../../44.png'
import styles from './index.module.css';
import Header from './Header'
import Frame from './Frame'
import {useState,useEffect} from 'react';
const fetchResponse = await fetch('https://quiz.1000logos.net/quiz.json').then(response => response.json())
const {logos,logosplus} = fetchResponse;
    const generatePages = ()=>{
        const arr = Array.from({length:logos.length}, (_, i) => i + 1);
        return arr
    }
    const pages = generatePages();
    const allTimers = [];
    for (let i = 0; i < pages.length; i++){ allTimers[i] = 50;}
    
    const allBombs = [];
    for (let i = 0; i < pages.length; i++){ allBombs[i] = false;}
export default function Game(props){
    const [coinCount,setCoinCount] = useState(0)
    const [timer,setTimer] = useState(0)
    const [timerRef,setTimerRef] = useState(null);
    const [currentPage,setCurrentPage] = useState(0)
    
    const [currentPageKey,setCurrentPageKey] = useState(0)
    const [finished,setFinished] = useState(false)
    
    const [timers,setTimers] = useState(allTimers);
    const [bombs,setBombs] = useState(allBombs)
    const swipeBack = ()=>{
        if(currentPage === 0){
            return;
        }
        setCurrentPage(currentPage - 1)
        
        setCurrentPageKey(currentPage - 1)
    }
    const swipeNext = ()=>{
        if(timers.every(e => e === -1 || e === 0)){
            setFinished(true)
            clearTimeout(timerRef)
            return;
        }
        else if(currentPage === (logos.length - 1)){
            setCurrentPage(0)
            
            setCurrentPageKey(0)
            return;
        }
        setCurrentPage(currentPage + 1)
        
        setCurrentPageKey(currentPage + 1)
    }
 useEffect(() => {
     if(!finished){
        setTimerRef(setTimeout(() => setTimer(timer + 1), 1000))
     }
    }, [timer]);
    return(
        <>
        <Header setPage={props.setPage} coinCount={coinCount}/>
        
        <div className={styles.main}>
        {
            finished ? 
                <div style={{margin:'auto',width:'calc(100% - 70px)'}}>
                    <h1 style={{textAlign:'center'}}>Congratulations! You reached the end!</h1>
                    <p style={{textAlign:'center'}}>You earned <span style={{color:'#f4d00d'}}>{coinCount}</span> coins.</p>
                    <p style={{textAlign:'center'}}>You spent: {timer} seconds.</p>
                    <p style={{textAlign:'center'}}>You answered correctly on: {timers.filter(e => e === -1).length} questions.</p>
                </div>
            :
            <>
                <div onClick={swipeBack} className={styles.arrow + ' ' + styles.arrow2 + (currentPage === 0 ? ' ' + styles.arrowdisabled : '')}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/></svg>
        <p className={styles.arrowtext1}>Back</p>
        </div><div style={{marginTop:30,display: 'flex', justifyContent: 'center',alignItems: 'center',flexDirection: 'column'}}>
        <div className={styles.counters}>
            <div><div><img src={icontick} alt="tick"/><p>{timers.filter(e => e === -1).length}</p></div>
            <div><img src={iconcross} alt="cross"/><p>{timers.filter(e => e === 0).length}</p></div></div>
            <div><div><img src={iconarrow} alt="arrow"/><p>{timers.filter(e => e > 0).length}</p></div>
            <div onClick={()=>{
                if(coinCount < 5 || bombs[pages[currentPage] - 1]){
                    return;
                }
                setCoinCount(coinCount - 5);
                const bombs1 = [...bombs];
                bombs1[pages[currentPage] - 1] = true;
                setBombs(bombs1)
                setCurrentPageKey(currentPageKey + 1)
            }}><img src={iconbomb} alt="bomb"/></div>
            </div> 
       </div>

        <Frame setTimer={(seconds)=>{
            const timers1 = [...timers];
            if(timers1[pages[currentPage] - 1] === -1){
                return;
            }
            timers1[pages[currentPage] - 1] = seconds;
            setTimers(timers1)
        }} timer={timers[pages[currentPage] - 1]} isBomb={bombs[pages[currentPage] - 1]} addCoin={()=>{setCoinCount(coinCount + 1)}} logo={logos[pages[currentPage] - 1]} logoplus={logosplus[pages[currentPage] - 1]} key={currentPageKey} currentIndex={pages[currentPage] - 1}/>
        </div><div onClick={swipeNext} className={styles.arrow}>
        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 20 20" viewBox="0 0 20 20" fill="#000000"><g><g><rect fill="none" height="20" width="20"/></g></g><g><polygon points="4.59,16.59 6,18 14,10 6,2 4.59,3.41 11.17,10"/></g></svg>
        <p className={styles.arrowtext2}>Next</p>
        </div>
        </>
        }
        
        </div>
        </>

    )
}