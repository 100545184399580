import styles from './index.module.css'
import logo from '../../mainlogo.png'
export default function Welcome(props){
    return(
        <div className={styles.main}>
        <a href="/"><div className={styles.arrow}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg></div></a>
            <img src={logo} alt="1000logos.net logo"/>
            <p>Check your memory and guess the different brands logos!</p>
            <button onClick={()=>{props.setPage('game')}}>Play</button>
        </div>
    )
}